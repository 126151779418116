#chatBoxWindow {
    width: 100%;
    height: 15%;
    bottom: 0;
    box-sizing: border-box;

}

textarea {
    margin: 0;
    width: 100%;
    height: 75%;
    box-sizing: border-box;
    background-color: #434343;
    color: white;
    font-size: large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-style: none;
    padding: 12px;
    resize: none;
}

#sendButton {
    width: 100%;
    height: 25%;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    background-color: #434343;
    border-style: none;
    color: white;
    transition: background-color 0.2s;
    padding-right: 15px;
    text-align: right;
}

#sendButton:hover {
    background-color: #505050;
}
