#streamWindow {
    background-color: rgb(33, 33, 33);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 80%;
    z-index: 99;
}

video {
    height: 100%;
    width: 100%;
}

#syncButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    height: 35px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #00d346;
    border-style: none;
    color: white;
    margin: 15px;
    opacity: 0%;
    transition: opacity 0.2s;
    z-index: 100;
    font-weight: bolder;
}

#streamWindow:hover #syncButton {
    opacity: 80%;
}

#syncButton:hover {
    opacity: 100% !important;
}

iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

@media screen and (orientation: portrait){
    #streamWindow {
        width: 100%;
        aspect-ratio: 16/9;
        height: auto;
    }
}