#AuthPage {
    background-color: rgb(33, 33, 33);
    color: white;
    font-weight: bold;
    font-size: large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ContentBox {
    background-color: #393939;
    width: 50%;
    height: 50%;
    padding: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 25px;
    box-shadow: 0px 10px 50px #101010;
}

#Form {
    height: 10%;
    width: 100%;
}

#Form input {
    height: 100%;
    width: 50%;
    background-color: #434343;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px ;
    border-style: none;
    color: white;
    font-weight: bold;
    font-size: large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-left: 12px;
    box-sizing: border-box;
}

#Form button {
    height: 100%;
    width: 25%;
    background-color: #434343;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px ;
    border-style: none;
    color: white;
    font-weight: bold;
    font-size: large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    box-sizing: border-box;
    border-left: #505050;
    border-left-width: 1px;
    border-left-style: solid ;
}

#Form button:hover {
    background-color: #505050;

}