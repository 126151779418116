#messageWindow {
    width: 100%;
    background-color: #666666;
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
}

#userName {
    font-weight: bold;
    display: flex;
    flex-basis: 100%;
    margin: 12px;
}

#messageText {
    display: flex;
    flex-basis: 100%;
    margin: 12px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    text-align: left;
}

@media only screen and (orientation: portrait) {
    #messageWindow {
        font-size: 1em;
    }
}