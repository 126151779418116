#sidebar {
    background-color: #393939;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 20%;
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

#tools {
    position: absolute;
    display: flex;
    flex-direction: row;
    background-color: #434343;
    width: 100%;
    height: 5%;
}

#tools > input {
    flex-basis: 75%;
    box-sizing: border-box;
    background-color: #434343;
    border-style: none;
    color: white;
    padding: 12px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#tools > button {
    flex-basis: 25%;
    box-sizing: border-box;
    background-color: #434343;
    border-style: none;
    border-left: #505050;
    border-left-width: 1px;
    border-left-style: solid;
    color: white;
    transition: background-color 0.2s;
}

#tools > button:hover {
    background-color: #505050;

}

#chatWindow {
    height: 85%;
    padding: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (orientation: portrait){
    #sidebar {
        width: 100%;
        height: auto;
        font-size: 1em;
    }
    
}